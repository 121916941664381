// import FiveOhOhSVG from '../src/static/images/page/error/500.svg';
import { Box, Container, Link, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { dataConstants } from '../src/constants';
// import onPageInit from '../src/actions/onPageInit';
import { useEffect } from 'react';
import * as send from '../lib/analytics/send';
import { getRandomStr } from '../src/helpers';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
function Error({ statusCode }) {
  const { asPath } = useRouter();
  // const [mounted, setMounted] = useState(false)

  // if (!mounted) {
  //   onPageInit(asPath);
  // }

  // useEffect(() => {
  //   setMounted(true)
  // }, [])

  useEffect(() => {
    send.uevent(undefined, `${statusCode}-${asPath}`)
  }, [asPath]);

  const popularFunds = [
    { fcik: '1067983', fundName: 'Warren Buffett' },
    { fcik: '1697748', fundName: 'Cathie Wood' },
    { fcik: '1350694', fundName: 'Ray Dalio' },
    { fcik: '1029160', fundName: 'George Soros' }
  ]

  return (
    <Container maxWidth="sm">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '78vh', justifyContent: 'center', overflow: 'auto' }}>
        <Box component="div">
          {/* {statusCode && statusCode !== {} && statusCode === 404 && <FourOhFourSVG />} */}
          <Typography variant="h1" sx={{ fontSize: '12rem', fontWeight: '600', color: '#e3e3e3', textAlign: 'center' }}>{statusCode}</Typography>
          {/* {statusCode && statusCode !== {} && statusCode !== 404 &&
            <Typography variant="h1" sx={{ fontWeight: '600', color: '#9999993d', fontSize: '12rem', textAlign: 'center', mt: 10 }}>{statusCode}</Typography>} */}
        </Box>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1.2}
        >
          <Item elevation={0}>
            {/* <Typography
              variant="h6"
              component="div"
              sx={{
                color: '#ee6161',
                fontWeight: 600,
              }}
            >
              {statusCode === 404 ? `Sorry, we couldn't find that page!` : `Oops, something went wrong!`}
            </Typography> */}
          </Item>
          <Item elevation={0}>
            <Typography variant="subtitle1" gutterBottom component="div" sx={{ color: '#000000cc' }}>
              {statusCode === 404 ? `We can't seem to find the page you are looking for` : `Error ${statusCode}. We apologise and are fixing the problem.`}
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="div" sx={{ mt: 3 }} color="text.very_light_desc">
              {statusCode === 404 ? `Here are some helpful links instead:` : `Please try again at a later stage.`}
              {statusCode === 404 &&
                <Typography component='div' variant="caption" sx={{ fontSize: '1rem', mt: 2, '& a': { color: 'text.link' } }}>
                  {dataConstants?.PAGES?.filter(item => !item.subMenu)?.map((page) => (
                    <Link key={page.name} href={page.url} underline="hover">
                      {` ${page.name}, `}
                    </Link>
                  ))}
                </Typography>}
            </Typography>
          </Item>
        </Stack>
        <Box sx={{ textAlign: 'center' }}>
          <Typography component='div' variant="caption" sx={{ fontSize: '.9rem', mt: 2, '& a': { color: 'text.link' } }}>
            Tickers:&nbsp;
            {['AAPL', 'TSLA', 'AMZN', 'GOOG', 'MSFT', 'NVDA'].map((d, i) =>
              <Link key={getRandomStr(3)} href={`/stock/${d}`} underline="hover">
                {i <= 4 ? `${d}, ` : `${d}.`}
              </Link>
            )}
          </Typography>
          <Typography component='div' variant="caption" sx={{ fontSize: '.9rem', mt: 1, '& a': { color: 'text.link' } }}>
            Funds:&nbsp;
            {popularFunds.map((d, i) =>
              <Link key={getRandomStr(3)} href={`/funds/${d.fcik}`} underline="hover">
                {i <= 2 ? `${d.fundName}, ` : `${d.fundName}.`}
              </Link>
            )}
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}

export default Error
